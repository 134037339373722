/**
 * Get the assets that need extra spacing on the bottom
 * @param {*} assetComponents An array of assets
 * @returns An object with a boolean per index. The boolean is true if the social
 * needs some extra spacing on the bottom for the TD templates with a playbar
 */
const getAssetsWithTimeline = (assetComponents) => {
    const framesWithTimelineAsset = {};

    if (assetComponents) {
        assetComponents.forEach((asset, index) => {
            if (asset.props && ['dynamicVideoDesigned', 'dynamicAdDesigned'].includes(asset.props.type)) {
                framesWithTimelineAsset[index] = true;
            } else {
                framesWithTimelineAsset[index] = false;
            }
        });
    }

    return framesWithTimelineAsset;
};

/**
 * Check if the video has video's available in different languages
 * @param {*} framesRestructured
 * @param {*} language
 * @returns A transformed array of the framesRestructured array
 */
const checkMultiLanguageVideo = (framesRestructured, language) => {
    // If there are different video's on multiple languages, only return the correct video in the specific language
    framesRestructured = framesRestructured.map((item) => {
        if (item.video && item.video[language]) {
            return { ...item, video: item.video[language] };
        } else if (item.video && item.video.url === undefined) {
            // If the video is dependent on a language, but there is no video available in that language, return video:null
            // item.video.url will be undefined, because the url will be inside of the language object (item.video.EN.url)
            return { ...item, video: null };
        } else {
            return { ...item };
        }
    });

    // If the hasVideo property is true, always show the video. However, if the hasVideo property exists, but is false, remove the video.
    framesRestructured = framesRestructured.map((item) => {
        if (item.hasVideo) {
            return { ...item, asset: null, image: null };
        } else if (typeof item.hasVideo !== 'undefined' && !item.hasVideo) {
            return { ...item, video: null };
        } else {
            return { ...item };
        }
    });

    return framesRestructured;
};

export { getAssetsWithTimeline, checkMultiLanguageVideo };
