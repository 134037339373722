import React from 'react';
import PropTypes from 'prop-types';
import WeChatSingleOrganic from './wechat-single-organic';
import WeChatSingleAd from './wechat-single-ad';
import './../styles/main.scss';

class PreviewWeChat extends React.Component {
    static propTypes = {
        adType: PropTypes.string,
        advertiser: PropTypes.shape({
            name: PropTypes.string,
            logo: PropTypes.string
        }),
        text: PropTypes.string
    };

    static defaultProps = {
        adType: 'imageAd',
        advertiser: {
            name: 'Cape',
            logo: ''
        }
    };

    static getChild(adType, props) {
        let component = null;

        switch (adType) {
            case 'imageAd':
                component = <WeChatSingleAd {...props} />;
                break;

            case 'imageAdCard':
                component = <WeChatSingleAd {...props} />;
                break;

            case 'videoAd':
                component = <WeChatSingleAd {...props} />;
                break;

            case 'videoAdCard':
                component = <WeChatSingleAd {...props} />;
                break;

            case 'organic':
                component = <WeChatSingleOrganic {...props} />;
                break;

            default:
                component = null;
        }

        return component;
    }

    render() {
        const { adType } = this.props;

        return (
            <div className="social-preview__wechat">
                <div className="social-preview__wechat__wrapper">{PreviewWeChat.getChild(adType, this.props)}</div>
            </div>
        );
    }
}

export default PreviewWeChat;
