import React from 'react';
import PropTypes from 'prop-types';
import DynamicAsset from 'components/assets/DynamicAsset';
import MoreVertical from '../images/more_vertical.svg';
import IconSkip from '../images/icon_skip.svg';
import IconInfoOutline from '../images/icon_info_outline.svg';
import './../styles/videoAd.scss';

/**
 * Youtube Video Ad
 * This shows a preview of the video ads
 */
class YoutubeVideoAd extends React.Component {
    static propTypes = {
        adType: PropTypes.string,
        advertiser: PropTypes.shape({
            name: PropTypes.string,
            logo: PropTypes.string
        }),
        headline: PropTypes.string,
        displayUrl: PropTypes.string,
        asset: PropTypes.shape({
            type: PropTypes.string,
            url: PropTypes.string
        })
    };

    static defaultProps = {
        adType: 'videoAd'
    };

    constructor(props) {
        super(props);

        this.state = {};
    }

    /**
     * Get the assets from Youtube
     */
    getAsset = () => {
        const { assetComponents, asset, image, video } = this.props;
        // existing prop
        if (assetComponents) {
            if (assetComponents.length > 0) {
                // Clone to add the controls props
                return React.cloneElement(assetComponents[0][0], { controls: false });
            } else {
                return false;
            }
        }
        // Create in component
        else {
            return (
                <DynamicAsset
                    {...asset}
                    image={image}
                    video={video}
                    frameWidth={asset && asset.frameWidth ? asset.frameWidth : 1200}
                    frameHeight={asset && asset.frameHeight ? asset.frameHeight : 620}
                    width={320}
                    controls={false}
                />
            );
        }
    };

    render() {
        const { advertiser, headline, displayUrl } = this.props;
        return (
            <div>
                <div className="social-preview__youtube__video-ad">
                    <div className="social-preview__youtube__video-ad__video">
                        <div className="social-preview__youtube__video-ad__video__asset-wrapper">{this.getAsset()}</div>

                        <div className="social-preview__youtube__video-ad__overlay">
                            <div className="social-preview__youtube__video-ad__video__advertiser-name">{advertiser.name}</div>
                            <div className="social-preview__youtube__video-ad__video__visit-advertiser">
                                <a target="_blank" href={displayUrl}>
                                    Visit Advertiser
                                </a>
                            </div>
                            <div className="social-preview__youtube__video-ad__video__skip-ad">
                                <div className="social-preview__youtube__video-ad__video__text">Skip Ad</div>
                                <div className="social-preview__youtube__video-ad__video__icon-skip">
                                    <img src={IconSkip} />
                                </div>
                            </div>
                            <div className="social-preview__youtube__video-ad__video__ad-dot">
                                Ad &bull; 1:51 <img src={IconInfoOutline} />{' '}
                            </div>
                        </div>
                        <div className="social-preview__youtube__video-ad__video__yellow-progress"></div>
                    </div>
                    <div className="social-preview__youtube__video-ad__advertiser-info">
                        <div className="social-preview__youtube__video-ad__advertiser-info__logo" style={{ backgroundImage: `url(${advertiser.logo})` }} />
                        <div className="social-preview__youtube__video-ad__advertiser-info__data">
                            <div className="social-preview__youtube__video-ad__advertiser-info__headline">{headline}</div>
                            <div className="social-preview__youtube__video-ad__advertiser-info__ad-info">
                                <div className="social-preview__youtube__video-ad__advertiser-info__ad">AD</div>
                                <div className="social-preview__youtube__video-ad__advertiser-info__text">{displayUrl}</div>
                            </div>
                        </div>
                        <div className="social-preview__youtube__video-ad__advertiser-info__icon-wrapper">
                            <img src={MoreVertical} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default YoutubeVideoAd;
