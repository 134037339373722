import React from 'react';
import PropTypes from 'prop-types';
import YoutubeVideoAd from './youtube-video-ad';
import YoutubeVideo from './youtube-video';
import './../styles/main.scss';

class PreviewYoutube extends React.Component {
    static propTypes = {
        adType: PropTypes.string,
        advertiser: PropTypes.shape({
            name: PropTypes.string,
            logo: PropTypes.string
        }),
        headline: PropTypes.string,
        displayUrl: PropTypes.string
    };

    static defaultProps = {
        adType: 'videoAd',
        advertiser: {
            name: 'Cape',
            logo: ''
        }
    };

    static getChild(adType, props) {
        let component = null;

        switch (adType) {
            case 'videoAd':
                component = <YoutubeVideoAd {...props} />;
                break;

            case 'video':
                component = <YoutubeVideo {...props} />;
                break;

            default:
                component = null;
        }

        return component;
    }

    render() {
        const { adType } = this.props;
        return <div className="social-preview__youtube">{PreviewYoutube.getChild(adType, this.props)}</div>;
    }
}

export default PreviewYoutube;
