import React from 'react';
import PropTypes from 'prop-types';
import DynamicAsset from 'components/assets/DynamicAsset';
import YoutubeInfo from '../images/youtube-info.jpg';
import './../styles/video.scss';

/**
 * Youtube video
 * This displays a preview of the Youtube organic video
 */
class YoutubeVideo extends React.Component {
    static propTypes = {
        adType: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        tags: PropTypes.array,
        categoryId: PropTypes.string,
        privacyStatus: PropTypes.string
    };

    static defaultProps = {
        adType: 'video',
        title: '',
        description: '',
        tags: [],
        categoryId: '',
        privacyStatus: ''
    };

    constructor(props) {
        super(props);

        this.state = {};
    }

    getAsset = () => {
        const { assetComponents, asset, image, video } = this.props;

        // Existing prop
        if (assetComponents) {
            return assetComponents[0] && assetComponents[0][0] ? assetComponents[0][0] : assetComponents[0];
        }
        // Create in component
        else {
            return (
                <DynamicAsset
                    {...asset}
                    image={image}
                    video={video}
                    frameWidth={asset && asset.frameWidth ? asset.frameWidth : 1200}
                    frameHeight={asset && asset.frameHeight ? asset.frameHeight : 620}
                    width={320}
                    controls={true}
                />
            );
        }
    };

    render() {
        const { title, tags } = this.props;

        return (
            <div className="social-preview__youtube__video">
                <div className="social-preview__youtube__video__asset-wrapper">{this.getAsset()}</div>

                {(title || (tags && tags.length > 0)) && (
                    <div className="social-preview__youtube__video__copy">
                        {tags && tags.length > 0 && (
                            <div className="video__tags">
                                {tags.map((tag) => {
                                    return <span>#{tag}</span>;
                                })}
                            </div>
                        )}
                        <div className="social-preview__youtube__video__title">{title}</div>
                    </div>
                )}

                <div className="social-preview__youtube__video__info">
                    <img src={YoutubeInfo} />
                </div>
            </div>
        );
    }
}

export default YoutubeVideo;
