import React from 'react';
import PropTypes from 'prop-types';
import DynamicAsset from 'components/assets/DynamicAsset';
import './../styles/single-organic.scss';

class WeChatSingleOrganic extends React.Component {
    static propTypes = {
        asset: PropTypes.object
    };

    static defaultProps = {};

    getAsset = () => {
        const { assetComponents, asset, image, video } = this.props;

        // existing prop
        if (assetComponents) {
            return assetComponents[0] && assetComponents[0][0] ? assetComponents[0][0] : assetComponents[0];
        }
        // Create in component
        else {
            return (
                <DynamicAsset
                    {...asset}
                    image={image}
                    video={video}
                    frameWidth={asset.frameWidth ? asset.frameWidth : 900}
                    frameHeight={asset.frameHeight ? asset.frameHeight : 383}
                    width={304}
                />
            );
        }
    };

    render() {
        const { headline, description } = this.props;

        return (
            <div className="wechat-single-organic">
                <div className="wechat-single-organic__date">20 May 2021 01:06</div>
                <div className="wechat-single-organic__card">
                    <div className="wechat-single-organic__card__asset-wrapper">{this.getAsset()}</div>
                    <div className="wechat-single-organic__card__copy">
                        <div className="wechat-single-organic__card__copy__headline">{headline}</div>
                        <div className="wechat-single-organic__card__copy__description">{description}</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default WeChatSingleOrganic;
