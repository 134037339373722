import React from 'react';
import PropTypes from 'prop-types';
import DynamicAsset from 'components/assets/DynamicAsset';
import iconTop from '../images/icon_top.jpg';
import iconBottom from '../images/icon_bottom.jpg';
import iconLink from '../images/icon_link.png';
import './../styles/single-ad.scss';

class WeChatSingleAd extends React.Component {
    static propTypes = {
        asset: PropTypes.object
    };
    static defaultProps = {};

    getAsset = () => {
        const { assetComponents, asset, image, video, adType } = this.props;

        let width = 200,
            frameWidth = 800,
            frameHeight = 800;

        if (adType === 'imageAdCard' || adType === 'videoAdCard') width = 240;
        if (adType === 'imageAdCard') frameHeight = 450;
        if (adType === 'videoAd') {
            frameWidth = 640;
            frameHeight = 480;
        }
        if (adType === 'videoAdCard') {
            frameWidth = 640;
            frameHeight = 360;
        }

        // existing prop
        if (assetComponents) {
            return assetComponents[0] && assetComponents[0][0] ? assetComponents[0][0] : assetComponents[0];
        }
        // Create in component
        else {
            return (
                <DynamicAsset
                    {...asset}
                    image={image}
                    video={video}
                    frameWidth={asset.frameWidth ? asset.frameWidth : frameWidth}
                    frameHeight={asset.frameHeight ? asset.frameHeight : frameHeight}
                    width={width}
                />
            );
        }
    };

    render() {
        const { adType, advertiser, text, url, headline, description } = this.props;

        return (
            <div className="wechat-single-ad">
                <div className="wechat-single-ad__logo-wrapper">
                    <div className="wechat-single-ad__logo-wrapper__logo" style={{ backgroundImage: `url(${advertiser.logo})` }}></div>
                </div>
                <div className="wechat-single-ad__content">
                    <div className="wechat-single-ad__content__top">
                        <div className="wechat-single-ad__content__top__advertiser-name">{advertiser.name}</div>

                        <div className="wechat-single-ad__content__top__icon">
                            <img src={iconTop} />
                        </div>
                    </div>

                    {(adType === 'imageAd' || adType === 'videoAd') && (
                        <div className="wechat-single-ad__content__regular">
                            <div className="wechat-single-ad__content__regular__text">{text}</div>
                            <div className="wechat-single-ad__content__regular__asset-wrapper">{this.getAsset()}</div>
                            {url && (
                                <div className="wechat-single-ad__content__regular__url">
                                    <div className="wechat-single-ad__content__regular__url__text">{url}</div>
                                    <div className="wechat-single-ad__content__regular__url__icon">
                                        <img src={iconLink} />
                                    </div>
                                </div>
                            )}
                        </div>
                    )}

                    {(adType === 'imageAdCard' || adType === 'videoAdCard') && (
                        <div className="wechat-single-ad__content__card">
                            <div className="wechat-single-ad__content__card__asset-wrapper">{this.getAsset()}</div>
                            <div className="wechat-single-ad__content__card__headline">{headline}</div>
                            <div className="wechat-single-ad__content__card__description">{description}</div>
                        </div>
                    )}

                    <div className="wechat-single-ad__content__bottom">
                        <div className="wechat-single-ad__content__bottom__date">20 May 2021 01:06</div>
                        <div className="wechat-single-ad__content__bottom__icon">
                            <img src={iconBottom} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default WeChatSingleAd;
