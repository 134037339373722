// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../SocialEditorBlock/images/social-preview-placeholder.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "/* Units are deprecated, use the $s* variables instead */\n/* Sizes using rem. This is based on a default 16px */\n/* This is to provide better control and consistency in managing stacking components */\n/* Custom sizes */\n.social-preview__youtube {\n  background: #fff;\n  border: 1px solid rgba(0, 0, 0, 0.2);\n}\n.social-preview__youtube__video {\n  border: 1px solid rgba(0, 0, 0, 0.12);\n  width: 320px;\n}\n.social-preview__youtube__video__asset-wrapper {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  background-position: center;\n  min-height: 168px;\n}\n.social-preview__youtube__video__copy {\n  padding: 8px 16px 0 16px;\n  background: white;\n  border: 1px solid white;\n  overflow-wrap: break-word;\n  word-wrap: break-word;\n}\n.social-preview__youtube__video__tags {\n  display: flex;\n  color: #069ee5;\n  width: 100%;\n  font-size: 0.875rem;\n}\n.social-preview__youtube__video__tags span {\n  margin-right: 4px;\n}\n.social-preview__youtube__video__title {\n  font-weight: 600;\n  width: 100%;\n  margin-top: 2px;\n  overflow-wrap: break-word;\n  word-wrap: break-word;\n}\n.social-preview__youtube__video__info {\n  width: 100%;\n}\n.social-preview__youtube__video__info img {\n  width: 100%;\n  height: 100%;\n}", ""]);
// Exports
module.exports = exports;
